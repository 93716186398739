import React from "react";
import Layout from "../components/layout";
import psCover from "../images/album_cover/ps-500.png";
import { Link } from "gatsby";
import { graphql } from "gatsby";

const IndexPage = ({ data }) => (
	<Layout>
		<section className="hero is-primary is-primary">
			<div className="hero-body has-bg-img secondary">
				<div className="container has-text-centered">
					<span className="subtitle">
						cool! - thanks for visiting - now just click one of those big links
						below
					</span>
				</div>
			</div>
		</section>
		<section className="section">
			<div className="container home is-fluid">
				<h1 class="is-size-3">
					<span className="_title">{data.site.siteMetadata.title}</span>
					<span className="dash"> &ndash; </span>
					<span className="_subtitle">{data.site.siteMetadata.subtitle}</span>
				</h1>
				<blockquote className="blurb">
					{data.site.siteMetadata.blurb}
				</blockquote>
				<div className="columns">
					<div className="column">
						<p class="is-size-4">
							<Link to="/music">About the music</Link>
						</p>
						<p className="note">
							Where you can find it online, get the full lyrics
						</p>

						<p class="is-size-4">
							<Link to="/podcast">About the podcast</Link>
						</p>
						<p className="note">
							Hear the first episode of the deeper story and subscribe
						</p>

						<p class="is-size-4">
							<Link to="/people">About the people</Link>
						</p>
						<p className="note">Meet the creative artists behind the project</p>

						<div class="contact">
							Email us at{" "}
							<a href="mailto:planetandsky@gmail.com">planetandsky@gmail.com</a>
							. We'd love to hear your feedback.
						</div>
					</div>
					<div className="column home cover has-text-centered">
						<Link to="/music">
							<img
								src={psCover}
								className="cover"
								alt="Planet and Sky album cover"
							/>
						</Link>
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export default IndexPage;

export const query = graphql`
	query {
		site {
			siteMetadata {
				title
				subtitle
				blurb
			}
		}
	}
`;
